import { defineNuxtRouteMiddleware, useRequestEvent } from '#imports'
import { setHeader } from 'h3'

export default defineNuxtRouteMiddleware(() => {
  if (import.meta.server) {
    setHeader(useRequestEvent(), 'X-Frame-Options', 'SAMEORIGIN')
    setHeader(
      useRequestEvent(),
      'Content-Security-Policy',
      "frame-ancestors 'self'"
    )
  }
})
